/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 29, 2021 */
@font-face {
    font-family: 'gothambold';
    src: url('gothambold-webfont.woff2') format('woff2'),
         url('gothambold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambook';
    src: url('gothambook-webfont.woff2') format('woff2'),
         url('gothambook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothammedium';
    src: url('gothammedium-webfont.woff2') format('woff2'),
         url('gothammedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothammedium';
    src: url('gothammedium_1-webfont.woff2') format('woff2'),
         url('gothammedium_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothamblack';
    src: url('gotham-black-webfont.woff2') format('woff2'),
         url('gotham-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}