
body {
    overflow: hidden;
    font-size: 16px;
    /* font-family: 'Gotham' !important; */
         font-family: 'gothambook' !important;
    color: black !important;
}
h1,h2,p,h3,h4,h5,h6,span{ 
    margin-bottom: 0px !important;   
     /* font-family: 'Gotham' !important; */
          font-family: 'gothambook' !important;
}

h2 {
    font-size: 1.875rem !important;
}
h4 {
    font-size: 1.5625rem !important;
}
p{
    font-weight: 400 !important;
}
.weee{
    font-family: 'gothambook' !important;
    position:absolute;
    top:1.5rem;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    z-index: 13;
    color: white;
    transition: 0s all!important;
    right:30px;
    top:0;

}
.weee.active{
    visibility: visible;
    opacity: 1;
    transform:translateX(calc(-100vw - 100%));
    transition:5s transform linear!important;
}
.cursor-pointer{
    cursor: pointer;
}
.text-black {
    color: black !important;
}

.text-4d ,.text-4d  *{
    color: #4D4D4D;
}
.py-70 { 
    padding-top: 70px;
    padding-bottom: 70px;
}
.social-icons {
    position: fixed;
    transition: .5s;
    right: 0;
    bottom: 0;
    display: inline;
    text-align: center;
    z-index: 14;
}
.menu-top-icons {
    position: fixed;
    transition: 1s;
    right: 0;
    display: inline;
    text-align: center;
    z-index: 14;
    top: 0;
}
.white-menu {
    position: fixed;
    transition: 1s;
    z-index: 14;
    right: 0;
    top: 0;
    height: 100%;
    width: 90px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateX(100%);
}
.menu-right , .white-menu {
    transition: 1s;
}
.stack-back  .menu-right.open .white-menu {
/* opacity: 0; */
transform: translateX(100%);
pointer-events: none;
}
.stack-back  .open.menu-right  {
pointer-events: none;

}
.menu-right.open .white-menu {
    background-color: white;
    transform: translateX(0);
    box-shadow: -1px 1px 20px 20px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);

}

.pointer {
    cursor: pointer;
}
.menu-right  {
    z-index: 12;
}

.menu-right svg *  {
    transition: 1s ;
}

.menu-right.open svg * {
    fill:black;
}

.menu-top-icons ,.social-icons{
    /* z-index: 999999999999999; */
    transition: .5s;
}
.stack-back .menu-right  .menu-top-icons ,.stack-back  .social-icons {
    transform: translateX(calc(100% + 60px ));
}
.stack-back .open.menu-right  .menu-top-icons ,
.stack-back .open.menu-right  .social-icons  {
     transform: translateX(0) !important;
}
.menu-top-icons div.icon-row{
    margin-right: 30px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
}

.social-icons  div.icons-row{
    margin-right: 30px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}
.social-icons  div.icons-row:last-child {
     margin-bottom: 0px !important;
}
.fb-icon  {
    transform: translate(-40px,50px);
    transition: .5s;
}
.insta-icon {
    transition: .5s;
     transform: translate(0px,30px);
}
.tw-icon  {
    transition: .5s;
    transform: translate(-56px,-10px);
}
.menu-right.open .planets-icon {
    transform: translate(calc((30px + 30px) * 1), calc((30px + 30px) * 1)) !important; 
}

.menu-right.open  .search-icon {
   transform: translate(calc((30px + 30px) * 2), calc((30px + 30px) * 2)) !important; 
}

.menu-right.open  .cmt-icon{
    transform: translate(calc((30px + 30px) * 3), calc((30px + 30px) * 3))!important; 
}
.menu-right.open  .circle-icon  {
     transform: translate(calc((30px + 30px) * 4), calc((30px + 30px) * 4))!important; 
}
.menu-right.open .fb-icon  , 
.menu-right.open .insta-icon  , 
.menu-right.open .tw-icon  {
    transform: translate(0);
}
.mail-icon {
   transform: translateX(130px);
    transition: .5s;
}
.menu-right.open  .mail-icon  {
    transform: translateX(0);
}
.pos-z {
    position: relative;
    /* z-index: 14; */
}
.menu-top-icons {
    transition: .75s;
}

.menu-top-icons * {
    transition: .75s;
}
.black-popup {
    background: black;
    z-index: 15!important;
    opacity: 0!important;
    transition: .5s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* visibility: hidden; */
    pointer-events: none;
}
.white-box {
    position: absolute;
    left: 0;
    height: 100vh;
    background-color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    top: 0;
      
}
.side-popup.show  .white-box  {
  box-shadow: -1px 1px 20px 20px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.side-popup {
    opacity: 1;
    z-index: 15;
    transition: 1s;
    position: fixed;
    /* visibility: hidden;; */
    top: 0;
    left: 0;
    width: 100%;
    max-width: 50%;
    transform: translateX(calc(-100% ));
    height: 100%;
     /* transition-timing-function: ease-in-out; */
     transition:0s all !important
}
.side-popup.search{
    max-width: unset;
}
.side-popup.wtf{
    max-width: 50%;
}
.side-popup.htf{
    max-width: 66.66667%;
}
.side-popup.projects{
    max-width: 58.33%;
}
.side-popup.contact{
    max-width: 600px;;
}
.side-popup.show {
    transform: translateX(0);
    transition:1s all !important

}
.side-popup .box-container {
    padding: 0 95px;
    overflow-y: auto;
    max-height: calc(100% - 100px);
    margin: 50px 0;
}
.side-popup .box-container .scrollview {
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    width: 100%;
    overflow-x: hidden;
}
.side-popup .box-container-sm {
    padding: 0 95px;
    /* overflow-y: auto; */
    /* max-height: calc(100% - 70px); */
    margin: 50px 0;
}
.side-popup .box-container-sm .scrollview {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    width: 100%;
    overflow-x: hidden;
}
.side-popup .box-container-0 {
    padding: 0 65px;
    overflow-y: auto;
    /* max-height: calc(100% - 50px); */
    margin-top: 50px;
    /* z-index: 13; */
}
.side-popup .box-container-0 .scrollview {
    overflow-y: auto;
    max-height: calc(100vh - 230px);
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 150px;
}
.black-popup .box-container {
   padding: 0 80px;
    overflow-y: auto;
    max-height: calc(100% - 60px);
    margin: 30px  0;
}

.contact-form.scrollview  {
   max-height: calc(100vh  - 350px) !important;
}
.black-popup  * {
    opacity: 0;
    transition: .5s;
}
.black-popup.show {
    pointer-events: all;
    /* z-index: 15!important; */
    opacity: 1!important;
    /* visibility: visible; */
}
.black-popup.show  * {
    transition: .5s;
    opacity: 1;
}
.theme-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(242,12,70,1) 0%, rgba(164,0,128,1) 100%) !important;
}
.menu-arrow {
    position: absolute;
    top: 50%;
}
.fh-100 {
    height: 100vh;
}
.py-30  {
    padding:30px 0;
}
.pb-70 {
    padding-bottom:70px;
}
.mt-70 {
    margin-top:70px;
}
.planets-icon svg{
    transform: scale(1.3);
}
.contact-form a {
    color: #772ADB;
    font-size:18px;
    transition:.5s;
    margin-top: 36px;
    margin-bottom: 5px;
    display: block;

}
a:hover {
    text-decoration:none !important; 
}

.contact-form a:hover {
    color:gray;
}

p.input-title{
    margin-bottom:14px !important;
    font-size:15px;
    margin-top: 30px;

}

input {
    height: 40px;
    background:#F2F2F2;
    padding-left:20px;
    padding-right:20px;
    width:100%;
}

input:focus ,textarea:focus {
    outline:none !important;
}
input, textarea {
    border:0px;
    border-radius:18.5px;
    font-size: 15px;
    transition: .5s !important;
    color:black;
}

textarea {
    background:#F2F2F2;
    width:100%;
    resize: none !important;
    height: 275px;
    padding:20px;
}


textarea::placeholder {
    color:#B3B3B3;
           font-family: 'gothambook' !important;
}
.submit-wrapper div {
 width: 126px;
    height: 40px;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 18.5px;
    transition: .5s;

    overflow: hidden;
}
.submit-wrapper .color1 {
    background: rgb(234,96,96);
    z-index: 1;
    background-image: linear-gradient(90deg, rgba(234,96,96,1) 0%, rgba(113,23,234,1) 100%);
}
.submit-wrapper .color2 {
    z-index: 2;
    opacity: 0;
    background: rgba(113,23,234,0.5);
    background-image: linear-gradient(90deg, rgba(234,96,96,0.5) 0%, rgba(113,23,234,0.5) 100%);
}
.submit-wrapper .loader-container > div {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.success{
    color:green
}
input[type="submit"]{
    width: 126px;
    transition: .5s;
    background-color: transparent;
    z-index: 10;
}
.submit-wrapper:hover .color1 {
    opacity: 0;
}
.submit-wrapper:hover .color2 {
    opacity: 1 !important;
}



.test {
       display: inline-block;
    position: fixed;
    top: 50%;
    border-radius: 50%;
    right: 10%;
    width: 194px;
    height: 194px;
    z-index: 12;
}
.what-the-fuck {
    display: inline-block;
    position: fixed;
    top: 50%;
    border-radius: 50%;
    right: 30%;
    width: 194px;
    background-color: #42E695;
    height: 194px;
    z-index: 12;
}

.close-btn {
    position: fixed;
    top: 50px;
    left: 30px;
    width: 30px;
    height: 30px;
    z-index: 14;
}
.scale-hover {
    transition: .5s;
    transform: scale(1.0);

}
.overflow-hidden {
    overflow: hidden;
}
.scale-hover:hover {
    transform: scale(1.1);
}
.z-max {
    z-index: 14;
    position: relative;
}

.social-icons svg {
    transition: .5s;
}
.social-icons svg:hover{
    transform: translateY(5px);
}
.img-wrapper {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
   
    
}
.img-wrapper  img {
    position: absolute;
    top: 0;
    left: 0;
    transition: .5s;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.who-single-item img {
    border-radius: 50%;
}
.text-blue {
    color: #266DE8;
}
.bg-blue {
    background-color: #266DE8;
}
h3 {
    font-size: 22px !important;
     font-family: 'gothammedium' !important;
}
h4 {
    font-size: 25px;
}
h2 {
    font-size: 30px;
}
.fw-black {
      font-family: 'gothamblack' !important;
}
.fw-normal ,.fw-book{
    /* font-weight: normal !important; */
     font-family: 'gothambook' !important;
}
.fw-medium {
    /* font-weight: 500 !important; */
    font-family: 'gothammedium' !important;
}
.fw-bold {
    /* font-weight: bold !important ; */
    font-family: 'gothambold' !important;
}
.btn-whodf  {
    border-radius: 15.3px;
    height: 30px;
    font-size: 12px;
    transition: .5s;
    line-height: 30px;
}
.fs-8 {
    font-size: 8px;
}
.fs-10 {
    font-size: 10px !important;
}
.fs-11 {
    font-size: 11px !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-44 {
    font-size: 44px !important;
}
.person-btn-wrapper .blue .color1 {
    background: rgb(119,42,219);
    z-index: 8;
    background: linear-gradient(90deg, rgba(119,42,219,1) 0%, rgba(38,109,232,1) 100%);
}
.person-btn-wrapper .blue .color2 {
    background: rgba(38,109,232,1);
    opacity: 0;
    z-index: 9;
}
.person-btn-wrapper .red .color1 {
    background: rgb(242,12,70);
    z-index: 8;
    background: linear-gradient(90deg, rgba(242,12,70,1) 0%, rgba(164,0,128,1) 100%);
}
.person-btn-wrapper .red .color2 {
      background: rgba(164,0,128,1);
      opacity: 0;
     z-index: 9;
}
.person-btn-wrapper .btn-whodf:hover .color2 {
    opacity: 1;
}

.person-btn-wrapper .color1 ,.person-btn-wrapper .color2{
    border-radius: 15.3px;
    height: 30px !important;
    padding: 0 10px;
    font-size: 14px;
    transition: .5s;
    line-height: 30px;
    display: inline-block;
    min-width: 134.06px;
    position: absolute;
}
.person-btn-wrapper .btn-whodf-text {
      min-width: 134.06px;
      font-size: 12px;
      z-index: 9;
}
.single-project-item .img-wrapper{
    border-radius: 20px!important;
}
/* .single-project-item:hover  img {

} */
.single-project-item:hover .img-wrapper img {
    transform: scale(1.3);
}
.project-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 50px;
    width: 100%;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    background:linear-gradient(0deg, rgb(0,0,0,0.5) 0%, rgb(55,55,55,0.1) 67.60000000000001%, rgb(82,81,81,0.1) 100%)
}
.project-info h4 {
    word-wrap: break-word;
    font-size: 22px;
}
.custom-col {
    padding-left: 4px!important;
    padding-right: 4px!important;
}
.custom-row {
    margin-left: -4px !important;
    margin-right: -4px!important;
}
.single-project-item  svg {
    z-index: 9;
}

.mt-4px {
    margin-top: 4px;
}
.single-team  {
max-width: 150px;

}
.project-image {
    width:100px;
    border-radius: 23.84px;
    object-fit: cover;
    overflow: hidden;
}
.lh-1p2 {
    line-height: 1.2 !important;
}
.user-socials .icons-row {
    transform: unset !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.icons-row a {
    line-height: 1;
}
.menu-right.open .mail-icon {
    margin-bottom: 40px !important;
}
.user-socials .icons-row svg path {
    fill: #266DE8;
}
.user-socials .icons-row  {
    display: inline-block;
    margin-right: 20px;
}
.border-radius-22 {
    border-radius: 22px;
}
.transition , svg path , svg circle, svg rect{
    transition: .5s;
}
.hover-bg-white-blue:hover{
    background-color: white !important;
    color: #266DE8 !important;
}
.user-socials:hover .icons-row svg:hover path {
    fill: white;
}
.circular-click svg {
    width: 35px;
}

.big-person-image {
    width: 100px;
}

@media ( min-width:1200px) {
    .circular-click:hover svg circle {
        fill: #266DE8 !important; 
    }
    .circular-click:hover svg   rect{
    fill: white !important;
    }
}

@media ( max-width:1000px) {
    .contact-popup .side-popup{
        max-width: unset!important;
    }
}



.involved-box-top {
     background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 120px; 
    margin-bottom: 4px;
    height: 40px;
    font-size: 10px  !important;
}
.blackBox {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 11;
    transition: .5s;
}
.blackBox.hide {
    opacity: 0;
    pointer-events: none;
}
.overflow-y-hidden {
    overflow: hidden !important;
}
.mx-height-none {
    max-height: unset !important;
}
.involved-box-bottom {
    overflow-y: auto;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    width: 120px;
}
.involved-box-bottom .scrollview {
    overflow-y: auto;
    height: calc(100vh - 350px);
}
.arrow-nav {
    width: 120px;
}
.search-hdf  input{
    max-width: 270px;
}

.search-hdf .icon-row  svg path{
    fill: #F2F2F2;
    
}
.search-hdf  input::-webkit-input-placeholder{
    color: #B3B3B3;
    font-size: 15px;
           font-family: 'gothambook' !important;
}
.filter-box {
    border-radius: 10px;
    background: #F2F2F2;
    position: absolute;
    transition: .5s;
    top: calc(100% + 15px);
    transform: translateY(20px);
    right: 0;
    z-index: 0;
    opacity: 0;
}
.filter-box.filter-show {
      transform: translateY(0) !important;
      opacity: 1;
      z-index: 10;
      max-height: 199px;
    overflow-y: scroll;
}
.text-80 {
    color: #808080;
}
.filter-item  {
    border-bottom:1px solid #E6E6E6;
}
.filter-box .filter-item:last-child {
    border-bottom:unset;

}
.filter-item img  {
    opacity: 0;
    transition: .5s;
}
.filter-item.active img {
    opacity: 1;
}
.stack-front .black-popup {
    /* z-index: 15 !important; */
}
.pt-35 {
    padding-top: 30px;
}
.w-85 {
    width: 75%;
}
.mt-14 {
    margin-top: 14px;
}
 .team-text {
     max-width: 275px;
 }
 .mb-7 {
     margin-bottom: 7px;
 }
 .mt-10 {
     margin-top: 10px;
 }
.custom-row-columns-big{
    width: calc(100% - 140px)
}
.pop-title {
        width: 120px ;
}
/* .mobile-z-position {
    z-index: 9999999999;
} */
@media (max-width:1600px){
    .pop-title {
        width: 110px ;
    }
    .w-85 {
    width: 85%;
}
    .py-70 {
        padding: 40px 0 ;
    }
     .side-popup .box-container-sm .scrollview {
        max-height: calc(100vh - 1.5rem - 180px);
    }
    .side-popup .box-container-0 .scrollview {
        max-height: calc(100vh - 180px);

    }
  
}
@media (max-height:600px ){
 .open.menu-right .menu-arrow ,.open.menu-right  .circle-icon {
    opacity: 0;
}
}

@media (max-height:510px ){
.open.menu-right  .social-icons , .open.menu-right .menu-arrow ,.open.menu-right  .circle-icon {
    opacity: 0;
}
}
@media (max-width: 991.98px) { 
    .overflow-y-hidden.mx-height-none {
        overflow-y: scroll !important;
    }
    .side-popup {
           /* transform: translateX(calc(-100% - 15px)); */
    }
.side-popup .box-container-sm  {
        overflow-y: auto; 
        max-height: calc(100% - 70px); 
    }
     .side-popup .box-container-sm .scrollview ,    .side-popup .box-container-0 .scrollview  {
        overflow-y: hidden !important;
        max-height:100%  !important;
    }
.side-popup .box-container-0 {
    max-height: calc(100% - 100px);
    padding-bottom: 50px !important;
}
.custom-row-columns-big ,
.involved-box-top, .involved-box-bottom {
    width: 100%;
}
.involved-box-bottom .scrollview  {
        height: auto !important;
      /* max-height: 220px; */
}

 }
 @media (max-width: 1200px) {
    .side-popup.projects,.side-popup.htf{
        max-width: 100%!important;
    } 
}
@media (max-width: 992px) {
    .side-popup{
        max-width: 100%!important;
    } 
}
@media (max-width: 767.98px) { 
    .side-popup  {
        width: 100%;
        max-width: 100%;
    }
 }
.search-box .search-icon {
        transform: translateY(-50%) !important;

    left: unset !important;
    position: absolute !important;
    width: 20px !important;
    height: 20px !important;
    right: -20px !important;
    top: 50% !important;
}
@media (max-width: 575.98px) { 
    .search-box .search-icon  {
        right: 0 !important;
    }
    .burger-sidebar {
        width: 230px !important;
    }
    .burger-sidebar >div {
        height: 40px !important;
        font-size: 13px !important;
    }
    p.input-title {
        font-size: 14px;
    }
    .search-box {
        width: 200px !important;
        right: -12px !important;
    }
    
    .contact-message-box {
        position: fixed !important;
        max-width: 240px;
        left: unset;
        right: -12px !important;
        top: 75px !important;
        transform: translateX(-50%) !important;
    }
    .contact-message-box .arrow {
        left: 180px !important;
    }
    .contact-message-box .upper .fw-black {
        padding-top: 10px !important;
    }
    .contact-message-box .upper .fw-medium {
        padding-bottom: 10px !important;
    }
    .contact-message-box .upper {
        display: flex;
        flex-direction: column;
        justify-content:center;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .close-btn {
        left: 15px;
        top: 26px;
    }
    .close-btn svg {
        width: 20px;
        height: 20px;
    }

   .menu-right.open .contact-message-box {
        left: unset;
        right: 75px !important;
        transform: unset !important;
        top: 160px !important;
    }
    .menu-right.open .contact-message-box .arrow {
        right: -11.5px !important;
        left: unset !important;
    }

    .involved-box-bottom .scrollview  {
        height: auto !important;
      /* max-height: 300px !important; */
}
    /* new */
    .side-popup .box-container-0 ,    .side-popup .box-container-sm ,.side-popup .box-container {
        max-height: 100%;
    }
    .pop-title {
        margin-top: -50px;
    }
    .side-popup .box-container-sm ,.side-popup .box-container,  .side-popup .box-container-0{
        margin-top: 0px !important;
        /* margin-bottom: 10px; */
        padding-top: 75px !important;
    }
    /* end new  */
    .white-box {
        border-radius: 0px !important;
    }
   
   .box-container ,  .box-container-sm ,
   .box-container-0{
        padding: 0 15px !important;
        margin-top: 75px !important;
    }
    .pop-title {
        margin-left: 30px;
    }
    .w-85 {
    width: 100%;
}

    .fs-44 {
        font-size: 30px !important;
    }
    .mt-70 {
        margin-top:50px;
    }
    .side-popup .box-container .scrollview {
         max-height: calc(100vh - 300px);

    }
    .contact-form a {
        font-size: 16px;
    }
    .pt-35 {
        padding-top: 25px;  
    }
    .mobile-z-position {
        z-index: 16;
     }

     /* menu  */
    .white-menu {
        width: 60px;
    }
    .menu-top-icons div.icon-row {
        width: 25px;
        height: 25px;
        margin-right: 17.5px;
    }
    .menu-right.open .planets-icon {
        transform: translate(calc((25px + 17.5px) * 1), calc((30px + 20px) * 1)) !important;
    }
    .menu-right.open .search-icon {
        transform: translate(calc((25px + 17.5px) * 2), calc((30px + 20px) * 2)) !important;
    }
    .menu-right.open .circle-icon {
        transform: translate(calc((25px + 17.5px) * 4), calc((30px + 20px) * 4))!important;
    }
    .menu-right.open .cmt-icon {
        transform: translate(calc((25px + 17.5px) * 3), calc((30px + 20px) * 3))!important;
    }
    .menu-right.open .search-box {
        top: 90px !important;
    }
    .menu-arrow img {
        width:15px ;
    }
    .social-icons div.icons-row {
        margin-right: 17.5px;
    }
    .social-icons svg {
        transition: .5s;
        width: 27px;
        height: 28px;
    }
  
}

  ::-webkit-scrollbar-track
{
    border-radius: 5px;
    background-color: #F2F2F2;
}

 ::-webkit-scrollbar
{
    width: 5px;
     border-radius: 5px;
    background-color: #F2F2F2;
}

 ::-webkit-scrollbar-thumb
{
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: black;
}
.team-black .black-popup{
  background-color: rgba(0,0,0,1);
}
.project-black  .black-popup{
      background-color: rgba(0,0,0,0.95);
}
.contact-popup .side-popup {
    max-width: 569px;
}
.black-popup .box-container::-webkit-scrollbar-thumb{
    background-color: #266DE8 !important;
}
.black-popup .close-btn path{
    fill:white;
}
.black-popup .close-btn{
    z-index: 17;
}
.involved-box-bottom .scrollview::-webkit-scrollbar{
    width: 5px !important;
}

.project-content {
    border-radius: 21.39px;
}
.project-content  div.scrollview {
  height: calc(100vh - 360px);
    overflow-y: auto;
}
.search-hdf  .search-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.lh-1 {
    line-height: 1;
}
.pt-40 {
     padding-top: 40px !important;
 }

.contact-message-box {
    opacity: 0;
    transform: translateY(15px);
    transition: .5s;
    pointer-events: none;
}

.contact-message-box.show {
 opacity: 1;   
    transform: translateY(0);
    pointer-events:visible;


}

.contact-message-box .upper {
    height: 73px;
    background-color: rgba(255,255,255,.75);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.contact-message-box .upper::after {
    content: "";
}

.contact-message-box .lower {
      height: 180px;
      margin-top: 5px;
      background-color: white;
      overflow-y: auto;
     border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.contact-message-box .upper .fw-black {
      padding-top: 20px;
}

.contact-message-box .lower input {
    height: 53px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: black !important;
    padding-right: 50px;
    border-radius: 20px;
}
.contact-message-box .lower input[type=submit] {
    opacity: 1;
    background-color: red;
    width: 30px !important;
    font-size: 0px;
    left: unset;
padding: 0px;
opacity: 0;
    right: 20px;
}
.single-message {
    padding: 16px 12px;
    width: 200px;
    margin-top: 25px;
    margin-bottom: 5px;
    background-color: #F2F2F2;
    border-radius: 15px;
    position: relative;
}
.single-message::after {
     content: "";
    transition: .5s;
    margin: auto;
    width: 0;
    height: 0;
    z-index: 12;
    position: absolute;
    right: 0;
    transform: rotate( 
182deg
 );
    border-left: -1px solid transparent;
    border-right: 21px solid transparent;
    border-bottom: 12px solid #F2F2F2;
    top: 100%;
    right: 25px;
}
.text-black-1a {
    color: #1A1A1A !important;
}
.contact-message-box .lower  img {
         position: absolute;
    bottom: 13px;
    right: 25px;
 }
.contact-message-box .lower input::placeholder ,.contact-message-box .lower input{
    font-size: 13px;
    color: #B3B3B3;
      font-family: 'gothambook' !important;
}
.contact-message-box .upper .fw-medium  {
    padding-top: 0px;
    padding-bottom: 16px;
}
.contact-message-box {
    position: absolute;
    width: 335px;
    right:0;
    top: 70px;
}
.menu-right.open .contact-message-box  {
    top: 170px;
    right: -50px;
}

.contact-message-box .arrow{
    transition: .5s;
    margin: auto;
    width: 0;
    height: 0;
    z-index: 12;
    position: absolute;
    right: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(255,255,255,.75);
        right: 32px;
    top: -8px;
}

.menu-right.open  .contact-message-box .arrow  {
    transform: rotate(90deg);
    top: 25px;
    right: -12px;
}
.messages {
    padding-right: 20px;
    overflow-y: auto;
    height: 125px;
    padding-bottom: 25px;
}
.search-box {
    position: absolute;
    top: 50px;
    right: 0;
    width: 250px;
    opacity: 0;
    transform: translateX(10px);

}
.search-box.show {
  opacity: 1;   
    transform: translateX(0);
    pointer-events:visible;

}
.menu-right.open .search-box {
    right: 0;
    top: 115px;
}
.search-box .arrow {
    transition: .5s;
    margin: auto;
    width: 0;
    height: 0;
    z-index: 12;
    position: absolute;
    right: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    right: 30px;
    top: -7px;
}

.menu-right.open .search-box .arrow   {
    right: -11px;
    top: 16px;
    transform: rotate(90deg);
}

.search-box input {
background-color: white;
}
.search-box input::placeholder{
    font-size: 14px;
    color: #B3B3B3;
    font-family: 'gothambook' !important;
}
.burger-sidebar  {
    position: absolute;
    right: -50px;
    width: 275px;
    padding-right: 50px;
    /* cursor: pointer; */
    top: 50px;
    opacity: 0;
    transition: .5s;
    pointer-events: none;
    transform: translateY(5px);
}
.burger-sidebar.show {
    opacity: 1;
    pointer-events: visible;
    transform: translateY(0);

}
.burger-sidebar >div:first-child {
    margin-top: 0px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.burger-sidebar >div:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.burger-sidebar >div {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    background-color: rgba(255,255,255,0.75);
    font-size: 15px;
    padding:0 14px ;
    cursor: pointer;
    position: relative;
}
.burger-sidebar>div.active ,.burger-sidebar>div:hover {
    background-color: white !important;
}
.burger-sidebar  .arrow {
   transition: .5s;
    margin: auto;
    width: 0;
    height: 0;
    z-index: 12;
    position: absolute;
    right: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(255,255,255);;
    right: -12px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    opacity: 0;
    visibility: hidden !important; 
}
.menu-right.open .burger-sidebar  .arrow  {
    visibility: visible !important;
}
.burger-sidebar>div.active  .arrow , 
.burger-sidebar>div:hover  span.arrow{
    opacity: 1 !important;
}

.open.menu-right  .burger-menu-icon:hover .burger-sidebar {
    opacity: 1;
}
.open.menu-right    .burger-sidebar.show  {
  pointer-events:visible;
}
.menu-stack.pointer-none {
    pointer-events: none !important;
}
.open.menu-right .burger-sidebar {
    right: 0;
}
.noclick {
    position: fixed;
    z-index: 13;
    width: 100%;
    height: 100%;
}

.bg-linear {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    transition: opacity 1.5s;
}
.linear-default {
    opacity: 1;
    /* background-image: black !important; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    /*  */
    margin: 0;
    height: 100vh;
    transition: background-image 1.5s;
}
.linear-default.loaded {
    background-image: url('./assets/bgBlue.jpg');
}
#starscontainer {
    animation: rotate 1100s linear infinite;
    position:absolute;
    top:0;
    left:0;
    height:200vh;
    width:200vh;
    transform-origin:center;
}



.single-star.animatestars {
     opacity: 0;
    /* animation-delay: 10s; */
    -webkit-animation: arrowInOut 15s linear  infinite;
    animation: arrowInOut 15s  linear infinite;
}
@-webkit-keyframes arrowInOut {
 0%,100% {opacity: 0.2;}
 30%, 80% {opacity: 1;}
}
@keyframes arrowInOut {
 0%,100% {opacity: 0.2;}
 30%, 80% {opacity: 1;}
 }
@keyframes rotate {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(90deg);}
  75% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}
.single-star {
    background:white;
    height:1.5px;
    width:1.5px;
    display:inline-block;
    border-radius:50%;
}

.linear-wtf {
    background: rgb(87,202,133);
    background-image: linear-gradient(184deg, rgba(87,202,133,1) 0%, rgba(24,78,104,1) 100%);
}

.linear-htf {
    background: rgb(119,42,219);
    background: linear-gradient(184deg, rgba(119,42,219,1) 0%, rgba(38,109,232,1) 100%);
}
.linear-contact {
    background: rgb(234,96,96);
    background: linear-gradient(184deg, rgba(234,96,96,1) 0%, rgba(113,23,234,1) 100%);
}

.linear-projects {
    background: rgb(219,58,0);
    background: linear-gradient(184deg, rgba(219,58,0,1) 0%, rgba(219,10,91,1) 100%);
}

.bg-linear.show {
    opacity: 1;
}

.loading-msg {
    position: fixed;
    z-index: 13;
    width: 100%;
    text-align: center;
    transition: .5s;
    top: 70%;
    color: white;
    font-family: 'gothambook' !important;
}
.loading-msg.hide {
    opacity: 0;
}
.logo {
    position: fixed;
    width: 80%;
    margin: auto;
    z-index: 13;
    top: 50%;
    left: 50%;
    transition: .5s;
    transform: translate(-50%,-50%);
}
.hide .logo  {
    opacity: 0;
}
.mycanvas  {
    transition: 1s;
}
canvas.hide {
    opacity: 0;
}

#circlelogo {
    transition: .5s;
}

#circlelogo {
  animation: crescendo 1s alternate infinite ease-in;
}

@keyframes crescendo {
  0%   {transform: scale(1.0);}
  100% {transform: scale(1.5);}
}

.search-item {
    border-radius: 20px;
    background-color: #F2F2F2;
    overflow: hidden;
    padding:  30px 24px;
}
.search-image-wrapper {
    width: 154px;
    border-radius: 12px;
    overflow: hidden;
}
.search-item .title {
    font-size: 22px;
    margin-top: 15px;
}
.search-item .details {
    font-size: 16px;
    margin-top: 3px;
}
.search-search svg path {
    fill: #000 !important;
}

.search-box .search-icon  svg path {
    fill: black;
}
.search-box  input {
    padding-right: 35px !important;
}
 .menu-right.open .search-box .search-icon {
    transform: translateY(-50%) !important;
}
#scene {
    position: fixed;
    top: 0;
    z-index: 13;
    left: 0;
    right: 0;
    width: 100%;
    transition: 3s;
    height: 100%;
}
#scene.hide {
    opacity: 0;
}
#scene.remove {
    /* display: none; */
    pointer-events: none;
    z-index: 0;
}
.content-wtf footer small{
    max-width: 250px;
    font-size: 11px;
}
.content-wtf footer img{
    max-width: 60px;
    height: auto;
}
#main-canvas canvas{
    opacity: 0;
}
#main-canvas.active canvas{
    opacity: 1;
    transition:all 1.5s;
}
.load-more{
    position: relative;
}
.load-more button
{    position: relative;
}
.load-more div{
    display: flex;
    position: absolute;
    left:10px;
}
.half-opacity{
    opacity: 0.5;
}
.pe-none{
    pointer-events: none;
}
.search-results .row{
    margin-right:5px ;
    margin-left: -4px;
    max-width: 1053px;
}
.search-results .col, .search-results .col-1, .search-results .col-10, .search-results .col-11, .search-results .col-12, .search-results .col-2, .search-results .col-3, .search-results .col-4, .search-results .col-5, .search-results .col-6, .search-results .col-7, .search-results .col-8, .search-results .col-9, .search-results .col-auto, .search-results .col-lg, .search-results .col-lg-1, .search-results .col-lg-10, .search-results .col-lg-11, .search-results .col-lg-12, .search-results .col-lg-2, .search-results .col-lg-3, .search-results .col-lg-4, .search-results .col-lg-5, .search-results .col-lg-6, .search-results .col-lg-7, .search-results .col-lg-8, .search-results .col-lg-9, .search-results .col-lg-auto, .search-results .col-md, .search-results .col-md-1, .search-results .col-md-10, .search-results .col-md-11, .search-results .col-md-12, .search-results .col-md-2, .search-results .col-md-3, .search-results .col-md-4, .search-results .col-md-5, .search-results .col-md-6, .search-results .col-md-7, .search-results .col-md-8, .search-results .col-md-9, .search-results .col-md-auto, .search-results .col-sm, .search-results .col-sm-1, .search-results .col-sm-10, .search-results .col-sm-11, .search-results .col-sm-12, .search-results .col-sm-2, .search-results .col-sm-3, .search-results .col-sm-4, .search-results .col-sm-5, .search-results .col-sm-6, .search-results .col-sm-7, .search-results .col-sm-8, .search-results .col-sm-9, .search-results .col-sm-auto, .search-results .col-xl, .search-results .col-xl-1, .search-results .col-xl-10, .search-results .col-xl-11, .search-results .col-xl-12, .search-results .col-xl-2, .search-results .col-xl-3, .search-results .col-xl-4, .search-results .col-xl-5, .search-results .col-xl-6, .search-results .col-xl-7, .search-results .col-xl-8, .search-results .col-xl-9, .search-results .col-xl-auto
{
    padding-left: 4px;
    padding-right: 4px;
}
.search-result{
    background:#F2F2F2;
    border-radius: 20px;
    cursor: pointer;
    min-width: 230px;
    height: 318px;
}
.search-result .content-container{
    padding-top: 30px;
    padding-bottom: 34px;
}
.search-result .main-image{
    width: 154px;
    height: 154px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 11px;
    margin-bottom:21px ;
}
.search-result .icon{
    top: 27px;
    /* transform: translateY(-50%); */
    right: 24px;
}
.search-result .description{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    width: 183px;
    font-size:18px;
}
.search-result .name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    width: 183px;
    font-size:18px;
}
.search-result .name{
    font-size: 25px;
}
.small-person-image{
    cursor: pointer;
}
.small-person-image img{
    border-radius:13px;
}
.side-popup-close-container .close-btn{
    z-index: 13!important;
}
.pe-none{
    pointer-events: none;
}
.flex-shrink-0{
    flex-shrink: 0;
}